import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed, onUnmounted } from 'vue';
import { gql } from '@apollo/client/core';
import { omit } from 'ramda';

import { useNotification, useLoading } from '@/modules/core';

import { usePaymentTasks, usePatchTransaction, TASKS_QUERY } from './';

const PAYMENTS_COUNT_QUERY = gql`
  query payments($completed: Boolean, $supplierId: ID, $businessId: ID!) {
    payments(completed: $completed, supplierId: $supplierId, businessId: $businessId, limit: 1, offset: 0) {
      totalCount
    }
  }
`;

export const usePaymentsCount = (variables) => {
  const { error } = useNotification();
  const { result, onError } = useQuery(PAYMENTS_COUNT_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));
  const paymentConnection = computed(() => result?.value?.payments ?? { nodes: [], totalCount: 0 });
  const totalCount = computed(() => paymentConnection.value.totalCount);

  onError((err) => {
    console.error('payment - usePaymentsCount', err);
    error();
  });

  return { totalCount };
};

const PAYMENTS_BILLINGS_QUERY = gql`
  query payments(
    $ids: [ID!]
    $businessId: ID
    $supplierId: ID
    $completed: Boolean
    $isCustomerSide: Boolean
    $excludeInactiveCustomers: Boolean
  ) {
    payments(
      ids: $ids
      businessId: $businessId
      supplierId: $supplierId
      completed: $completed
      isCustomerSide: $isCustomerSide
      excludeInactiveCustomers: $excludeInactiveCustomers
    ) {
      nodes {
        id
        supplierId
        requestedDate
        date
        amount
        isRefund
        billingLinks {
          amount
          billingId
        }
      }
    }
  }
`;

export const usePaymentsBillingsInProgress = (variables, options) => {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(PAYMENTS_BILLINGS_QUERY, variables, options);
  const paymentConnection = computed(() => result?.value?.payments ?? { nodes: [] });
  const payments = computed(() => paymentConnection.value.nodes);

  onError((err) => {
    console.error('payment - usePaymentsBillingsInProgress', err);
    error();
  });

  return {
    payments,
    loading,
    refetch,
  };
};

const PAYMENTS_QUERY = gql`
  query payments($ids: [ID!], $businessId: ID!, $completed: Boolean) {
    payments(ids: $ids, businessId: $businessId, completed: $completed) {
      nodes {
        id
        supplierId
        requestedDate
        date
        amount
        isRefund
        completed
        billingLinks {
          amount
          billingId
          date
        }
        transactions {
          id
          paymentMethod
          instructionNumber
          reference
          date
          sourceBankAccount {
            bankNumber
            branchNumber
            accountNumber
            accountName
          }
          destinationBankAccount {
            bankNumber
            branchNumber
            accountNumber
            accountName
          }
          directDebit
        }
        failed
        createdAt
        createdBy {
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const usePayments = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(
    PAYMENTS_QUERY,
    () => omit(['paymentIds'], { ...variables.value, ids: variables.value?.paymentIds }),
    () => ({
      enabled:
        !!variables.value.businessId &&
        (!!variables.value?.paymentIds?.length || typeof variables.value.completed === 'boolean'),
    })
  );
  const paymentConnection = computed(() => result?.value?.payments ?? { nodes: [], totalCount: 0 });

  const payments = computed(() => paymentConnection.value.nodes);
  const totalCount = computed(() => paymentConnection.value.totalCount);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('payment - usePayments', err);
    error();
  });

  return { payments, totalCount, loading, refetch };
};

export const usePaymentsInProgress = (variables, getPaymentByTransaction) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(PAYMENTS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && typeof variables.value.completed === 'boolean',
  }));
  const paymentConnection = computed(() => result?.value?.payments ?? { nodes: [] });
  const payments = computed(() => paymentConnection.value.nodes);
  const loading = useLoading(queryLoading, variables);

  const tasksVariables = { businessId: variables.value.businessId, type: 'uploadFile' };
  const { tasks, loading: paymentTaskLoading, refetch: refetchPaymentTasks } = usePaymentTasks(tasksVariables);

  const options = {
    update: (cache, { data: { paymentTransactionPatch: patchedTransaction } }) => {
      const patchedPayment = getPaymentByTransaction(patchedTransaction.id);

      const { tasksNew2: tasks } = cache.readQuery({
        query: TASKS_QUERY,
        variables: tasksVariables,
      });

      const newTasks = tasks.nodes.filter(({ data }) => data.paymentId !== patchedPayment.id);
      cache.writeQuery({
        query: TASKS_QUERY,
        variables: tasksVariables,
        data: {
          tasksNew2: {
            totalCount: tasks.totalCount - 1,
            nodes: newTasks,
          },
        },
      });
    },
  };
  const { patchTransaction, loading: patchTransactionLoading } = usePatchTransaction(options);

  onError((err) => {
    console.error('payment - usePaymentsInProgress', err);
    error();
  });

  return {
    payments,
    loading,
    refetch,
    paymentTasks: tasks,
    paymentTaskLoading,
    refetchPaymentTasks,
    patchTransaction,
    patchTransactionLoading,
  };
};

const PAYMENT_CREATE_MUTATION = gql`
  mutation paymentCreate($createParams: PaymentCreateInput!) {
    paymentCreate(createParams: $createParams) {
      id
      billingLinks {
        amount
        billingId
      }
    }
  }
`;

const onPaymentCreateDoneSubscribedCallbacks = [];
const onPaymentCreateErrorSubscribedCallbacks = [];

export const useCreatePayment = (options) => {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(PAYMENT_CREATE_MUTATION, options);

  onDone((result) => {
    onPaymentCreateDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onPaymentCreateErrorSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((param) => {
    console.error('payment - useCreatePayment', param);
    error();
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) =>
      onPaymentCreateDoneSubscribedCallbacks.splice(onPaymentCreateDoneSubscribedCallbacks.indexOf(cb), 1)
    );
    onErrorCallbacks.forEach((cb) =>
      onPaymentCreateErrorSubscribedCallbacks.splice(onPaymentCreateErrorSubscribedCallbacks.indexOf(cb), 1)
    );
  });

  return {
    createPayment: mutate,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onPaymentCreateDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onPaymentCreateErrorSubscribedCallbacks.push(callback);
    },
  };
};

const PAYMENTS_BY_MONTH_QUERY = gql`
  query paymentsByMonth(
    $businessId: ID
    $supplierId: ID
    $fromDate: ISODate!
    $toDate: ISODate!
    $isCustomerSide: Boolean
    $excludeInactiveCustomers: Boolean
  ) {
    paymentsByMonth(
      businessId: $businessId
      supplierId: $supplierId
      fromDate: $fromDate
      toDate: $toDate
      isCustomerSide: $isCustomerSide
      excludeInactiveCustomers: $excludeInactiveCustomers
    ) {
      nodes {
        amount
        date
      }
    }
  }
`;

export const usePaymentsByMonth = (variables, options) => {
  const { error } = useNotification();
  const { result, loading: queryLoading, onError, refetch } = useQuery(PAYMENTS_BY_MONTH_QUERY, variables, options);
  const payments = computed(() => result?.value?.paymentsByMonth.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('payment - usePaymentsByMonth', err);
    error();
  });

  return {
    payments,
    loading,
    refetch,
  };
};

const PAYMENT_QUERY = gql`
  query payment($id: ID!) {
    payment(id: $id) {
      id
      businessId
      customerId
      supplierId
      completed
      amount
      isRefund
      requestedDate
      date
      eventReferences {
        reference
        documentId
      }
      billingLinks {
        amount
        billingId
      }
      transactions {
        id
        paymentMethod
        instructionNumber
        reference
        date
        sourceBankAccount {
          bankNumber
          branchNumber
          accountNumber
          accountName
        }
        destinationBankAccount {
          bankNumber
          branchNumber
          accountNumber
          accountName
        }
        type
        number
        signedBy
        cardOwner
        directDebit
      }
      createdAt
      type
      initialBalance
    }
  }
`;

export const usePayment = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(PAYMENT_QUERY, variables, () => ({
    enabled: variables.value.id,
  }));
  const payment = computed(() => result?.value?.payment ?? {});
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('payment - usePayment', err);
    error();
  });

  return {
    payment,
    loading,
    refetch,
  };
};
